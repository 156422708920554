<template>
  <the-wrapper :isSpinner="isSpinner">
    <slot>
      <ServicesDescription :isPageWrapper="true">
        <template #title>
          Управление услугой «Колл-трекинг»
        </template>
        <template #body>
          <b-form>
            <span>
              <b> Номер телефона коллтрекинга</b>
            </span>
            <p>{{ ruleData.CalltrackingNumber }}</p>
            <span>
              <b>
                Номер, на который будет переадресован вызов
              </b>
            </span>
            <p>{{ ruleData.RedirectNumber }}</p>
            <b-form-group label="Описание объекта отслеживания" :label-class="$style.bold">
              <BaseTextInput v-model="ruleData.Text" :class="$style.textField"></BaseTextInput>
            </b-form-group>
            <b-form-group label="Подробное описание" :label-class="$style.bold">
              <BaseTextInput
                v-model="ruleData.Description"
                :class="$style.textField"
              ></BaseTextInput>
            </b-form-group>
            <b-form-group label="Дата начала действия" :label-class="$style.bold">
              <BaseInputDate
                v-model="ruleData.BeginTime"
                :class="$style.dateField"
                type="date"
              />
            </b-form-group>
            <b-form-group label="Дата окончания действия" :label-class="$style.bold">
              <BaseInputDate v-model="ruleData.EndTime" :class="$style.dateField" type="date" />
            </b-form-group>
          </b-form>
        </template>
        <template #footer>
          <BaseButton @click.stop.prevent="updateRule">
            <template #text>
              Сохранить
            </template>
          </BaseButton>
          <BaseButton @click.stop.prevent="createRule" :class="$style.btnMargin">
            <template #text>
              Сохранить как...
            </template>
          </BaseButton>
        </template>
      </ServicesDescription>
      <BaseBackButton />
    </slot>
  </the-wrapper>
</template>

<script>
import TheWrapper from '@/components/shared/TheWrapper.vue';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import BaseInputDate from '@/components/shared/inputs/BaseInputDate.vue';
import BaseBackButton from '@/components/shared/buttons/BaseBackButton.vue';

import URL from '@/basUrl';
import { getDetailsWithQuery, sendCustomerRequest } from '@/api/common';
import { catchFunction, processResponse } from '@/additional_helpers/helpers';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      isSpinner: true,
      id: this.$route.query.id,
      ruleData: {},
      checkBeginTime: ''
    };
  },

  components: {
    TheWrapper,
    ServicesDescription,
    BaseButton,
    BaseTextInput,
    BaseInputDate,
    BaseBackButton
  },

  created() {
    getDetailsWithQuery(URL.READ_RULE, `id=${this.id}`)
      .then((result) => {
        this.ruleData = result.data;
        this.ruleData.BeginTime = this.createDate(this.ruleData.BeginTime);
        this.ruleData.EndTime = this.createDate(this.ruleData.EndTime);
        this.checkBeginTime = result.data.BeginTime;
        this.SHOW_LOADING(false);
      })
      .catch((e) => catchFunction(e));
  },

  methods: {
    ...mapMutations({ SHOW_LOADING: 'SHOW_LOADING' }),

    sendRequestToServer(url, row) {
      sendCustomerRequest(url, row)
        .then((result) => {
          if (JSON.parse(result.text).Error) {
            catchFunction(JSON.parse(result.text).Error);
            return;
          }
          processResponse(result);
          this.$router.push({
            name: 'CallTracking'
          });
        })
        .catch((e) => catchFunction(e));
    },

    createDate(date) {
      return date !== null ? date.slice(0, 10) : null;
    },

    updateRule() {
      if (this.ruleData.BeginTime === this.checkBeginTime) {
        this.ruleData.BeginTime = null;
      }
      const row = JSON.stringify(this.ruleData);
      this.sendRequestToServer(URL.UPDATE_RULE, row);
    },

    createRule() {
      const row = JSON.stringify({
        Id: this.ruleData.Id,
        Description: this.ruleData.Description,
        Text: this.ruleData.Text,
        BeginTime: this.ruleData.BeginTime === this.checkBeginTime ? this.ruleData.BeginTime : null,
        EndTime: this.ruleData.EndTime
      });

      this.sendRequestToServer(URL.CREATE_RULE, row);
    }
  }
};
</script>

<style module>
.bold {
  font-weight: 600;
}

.btnMargin {
  margin-left: 1rem;
}

@media (min-width: 576px) {
  .textField,
  .dateField {
    max-width: 14.4375rem;
  }
}
</style>
